<template>
  <div class="daily-report">
    <header class="daily-report__header">
      <div class="daily-report__info">
        <h2>醫療總表</h2>
        <div class="daily-report__select-wrap">
          <select v-model="selectStaffId">
            <option value="0">所有醫師</option>
            <option v-for="staff in filteredStaffs" :value="staff.id" :key="staff.id">{{staff.fullName}}</option>
          </select>
        </div>
        <router-link class="daily-report__btn-beauty" to="/daily-report-beauty">美療總表</router-link>
        <!-- <a href='javascript:void(0);' class="daily-report__btn-beauty" @click="loadDailyReport">重新整理</a> -->
      </div>
      <div class="daily-report__date">
        <button class="daily-report__btn-prev" @click="addDay(-1)"></button>
        <button class="daily-report__btn-next" @click="addDay(1)"></button>
        <datepicker class="reservation-time__datepicker daily-report__btn-select-date" inputFormat="yyyy.MM.dd" v-model="date" />
      </div>
      <front-desk-menu :active-item="'daily-report-medical'" />
    </header>
    <main class="daily-report__main">
      <div class="daily-report__outpatient-wrap medical">
        <span>時間</span>
        <span v-for="treatmentType in treatmentTypes" :key="treatmentType">{{treatmentType.name}}</span>
      </div>
      <div class="daily-report__new-period-wrap medical">
        <div class="daily-report__new-period-row" v-for="time in times" :key="time">
          <div class="daily-report__new-period">
            <span>{{time}}</span>
          </div>
          <div class="daily-report__new-period" v-for="treatmentType in treatmentTypes" :key="treatmentType">
            <daily-report-medical-item v-for="r in findReseravations(treatmentType.id, time)" :key="r" :reservation="r" @cancel="onCancel" />
          </div>
        </div>
      </div>
    </main>
    <loading :isActive="isLoading" />
  </div>
</template>
<script>
import Datepicker from 'vue3-datepicker'
import { apiGetTreatmentTypes, apiGetDailyReportNew, apiGetStaffs, apiGetTimeSlots, apiCancel } from '../api/v1/reservations'
import { formatDate, formatTimeHHmm, formatTWBirthDay } from '../utils/utils'
import Loading from '../components/Loading.vue'
import FrontDeskMenu from '../components/FrontDeskMenu.vue'
import DailyReportMedicalItem from '../components/DailyReportMedicalItem.vue'

export default {
  name: 'DailyReportMedical',
  components: {
    Datepicker,
    Loading,
    FrontDeskMenu,
    DailyReportMedicalItem
  },
  watch: {
    searchParams () {
      this.loadTimeSlots()
      this.loadDailyReport()
    }
  },
  data () {
    return {
      keyword: '',
      reservations: [],
      staffs: [],
      selectStaffId: 0,
      treatmentTypes: [],
      times: [],
      interval: 20,
      date: new Date(),
      isLoading: false
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    filteredStaffs: function () {
      if (this.staffs) {
        return this.staffs.filter(staff => {
          return staff.fullName.includes(this.keyword)
        })
      }
      return []
    },
    searchParams () {
      return [this.date]
    }
  },
  methods: {
    loadData () {
      this.isLoading = true
      apiGetStaffs({ jobName: '醫生' }).then(data => {
        if (!data.error) {
          this.staffs = data.content
        }
      })
      this.loadTreatmentTypes()
      this.loadTimeSlots()
      this.loadDailyReport()
    },
    async loadTreatmentTypes () {
      this.treatmentTypes = await apiGetTreatmentTypes()
    },
    async loadTimeSlots () {
      const data = await apiGetTimeSlots()
      this.interval = data.medicalInterval
      this.times = this.date.getDay() === 6 ? data.medicalSaturday : data.medicalWeekday
    },
    async loadDailyReport () {
      this.isLoading = true
      const params = { is_medical: true, date: formatDate(this.date) }
      this.reservations = await apiGetDailyReportNew(params)
      this.isLoading = false
    },
    findReservationsInTimeSlot (time) {
      const date1 = new Date(`${formatDate(this.date, '/')} ${time}`)
      const date2 = new Date(date1.getTime() + this.interval * 60000)
      const time2 = formatTimeHHmm(date2)
      // return this.staffIds.map(staffId => this.reservations.find(r => r.treatmentStart >= time && r.treatmentStart < time2 && r.treatmentStaffId === staffId))
      return this.reservations.filter(r => r.treatmentStart >= time && r.treatmentStart < time2)
    },
    addDay (day) {
      var date = new Date(this.date)
      date.setDate(date.getDate() + day)
      this.date = date
    },
    findReseravations (treatmentTypeId, time) {
      const date1 = new Date(`${formatDate(this.date, '/')} ${time}`)
      const date2 = new Date(date1.getTime() + 20 * 60000)
      const time2 = formatTimeHHmm(date2)
      const reservations = this.reservations.filter(r => r.treatmentTypeId === treatmentTypeId && (r.treatmentStart >= time && r.treatmentStart < time2))
      if (this.selectStaffId > 0) {
        return reservations.filter(r => r.treatmentStaffId === this.selectStaffId)
      }
      return reservations
    },
    formatBirthDate (bdate) {
      return formatTWBirthDay(bdate)
    },
    // 取消預約
    onCancel (reservationId) {
      const params = {
        reservationId: reservationId
      }
      apiCancel(params).then(data => {
        if (data.error) {
          console.log(data.error.message)
          this.$swal('取消失敗', data.error.message, 'warning')
        } else {
          console.log(data.message)
          this.$swal('已取消', '', 'success')
          this.reservations = this.reservations.filter(r => r.reservationId !== reservationId)
        }
      }).catch(e => {
        console.log(e)
        this.$swal('取消失敗', e, 'error')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-ui {
  &__btn-delete {
    background-color: #f52b08;
    width: 12rem;
    text-align: center;
    line-height: 4rem;
    height: 4rem;
    text-align: center;
    border-radius: 4rem;
    color: #fff;
    border: 0;
    font-size: 1.8rem;
    margin: 0 auto;
    display: block;
    transition: all 300ms ease;
    &:active {
      background-color: #b6b6b6;
    }
  }
  &__btn-cancel {
    background-color: #ffffff;
    width: 12rem;
    text-align: center;
    line-height: 4rem;
    height: 4rem;
    text-align: center;
    border-radius: 4rem;
    color: #525252;
    border: 0;
    font-size: 1.8rem;
    margin: 0 auto;
    display: block;
    transition: all 300ms ease;
    &:active {
      background-color: #b6b6b6;
    }
  }
}
.period {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
// .daily-report__main {
//    width: fit-content;
// }
</style>
