<template>
  <div class="period-label" :class="{
        'status-1': contactStatus === '未聯絡',
        'status-2': contactStatus === '已聯絡：OK',
        'status-3': contactStatus === '已聯絡：已留言',
        'is-active': isCardExpanded
      }" @click="onOpenCard">
    <span class="period-label__name">{{reservation.patientName}}</span>
    <span class="period-label__number">{{fourDigitBirthday}}</span>
    <span class="period-label__treatment">{{reservation.treatmentName}}</span>
    <span class="period-label__host">{{reservation.treatmentStaffName}}</span>
    <!--new-period-->
    <div class="new-period medical">
      <button class="new-period__btn-close" @click.stop="onCloseCard"></button>
      <div class="new-period__avatar-wrap">
        <div class="new-period__avatar">
          <img :src="photo" alt="">
        </div>
        <button class="new-period__btn-card-menu" :class="{ 'is-active' : isCardMenuActive}" @click="toggleCardMenu"></button>
        <div class="new-period__card-option" :class="{ 'is-active' : isCardMenuActive}" @mouseleave="isCardMenuActive = false">
          <button class="new-period__btn" @click="onCancel">取消</button>
        </div>
      </div>
      <div class="new-period__detail">
        <div class="new-period__row">
          <h3 class="new-period__name">{{reservation.patientName}}</h3>
          <span class="new-period__number">{{reservation.patientPhoneLast4Digits}}</span>
        </div>
        <div class="new-period__row">
          <span class="new-period__vip">{{reservation.patientType}}</span>
          <span class="new-period__birthday">{{birthdayTW}}</span>
        </div>
        <div class="new-period__row">
          <span class="new-period__reserve">預約 {{reservation.scheduleTime}}</span>
          <span class="new-period__handle">處置 {{reservation.treatmentStart}} ({{reservation.treatmentDuration}}分鐘)</span>
        </div>
        <div class="new-period__row">
          <span class="new-period__course">{{reservation.treatmentName}} / {{reservation.treatmentStaffName}}</span>
        </div>
        <div class="new-period__status-wrap">
          <span class="new-period__status">聯繫情況</span>
          <div class="new-period__select-wrap" :class="contactStatusClass">
            <select v-model="contactStatus" @change="updateContactStatus">
              <option>未聯絡</option>
              <option>已聯絡：OK</option>
              <option>已聯絡：已留言</option>
            </select>
          </div>
        </div>
      </div>
      <div class="new-period__ps">PS: {{reservation.patientRemark1}}</div>
    </div>
    <!--new-period-->
  </div>
</template>

<script>
import { apiPostContactStatus } from '../api/v1/reservations'
import { formatTWBirthDay } from '../utils/utils'

export default {
  name: 'DailyReportMedicalItem',
  props: ['reservation'],
  emits: ['cancel'],
  data () {
    return {
      // photo: '',
      contactStatus: '',
      contactStatusClass: {
        'status-1': true,
        'status-2': false,
        'status-3': false
      },
      isCardMenuActive: false,
      isCardExpanded: false
    }
  },
  watch: {
    contactStatus () {
      this.contactStatusClass = {
        'status-1': this.contactStatus === '未聯絡',
        'status-2': this.contactStatus === '已聯絡：OK',
        'status-3': this.contactStatus === '已聯絡：已留言'
      }
    }
  },
  mounted () {
    this.contactStatus = this.reservation.contactStatus
  },
  computed: {
    photo () {
      return (this.reservation.gender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
    },
    birthdayTW () {
      return formatTWBirthDay(this.reservation.patientBirthDate)
    },
    fourDigitBirthday () {
      const parts = this.reservation.patientBirthDate.split('-')
      if (parts.length < 3) {
        return ''
      }
      return `${parts[1]}${parts[2]}`
    }
  },
  methods: {
    updateContactStatus () {
      apiPostContactStatus(this.reservation.reservationId, { status: this.contactStatus }).then(data => {
        console.log(data)
      }).catch(error => {
        this.$swal('變更失敗', error, 'error')
        console.log(error)
      })
    },
    toggleCardMenu () {
      this.isCardMenuActive = !this.isCardMenuActive
    },
    toggleCard () {
      this.isCardExpanded = !this.isCardExpanded
    },
    onOpenCard () {
      this.isCardExpanded = true
    },
    onCloseCard () {
      this.isCardExpanded = !this.isCardExpanded
    },
    onCancel () {
      this.$emit('cancel', this.reservation.reservationId)
    }
  }
}
</script>

<style>
.period-label__treatment {
    font-size: 1.3rem;
    padding-left: 0.5rem;
    max-width: 9rem;
    overflow: hidden;
    max-height: 2rem;
    text-overflow: clip;
}
</style>
